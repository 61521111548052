import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { foodserviceTrademarkPath } from 'routes'
import Layout from 'components/Layout'
import { Cards, CategoryCard } from '@accodeing/gatsby-theme-switsbake'

const Trademark = ({ trademark, image }) => (
  <CategoryCard
    title={trademark.name}
    linkTo={foodserviceTrademarkPath(trademark.id)}
  >
    <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      alt={trademark.name}
      objectFit='contain'
    />
  </CategoryCard>
)

const trademarkImage = (images, trademarkId) => {
  const image = images.filter((image) => image.name === trademarkId)[0]

  if (!image) {
    console.error(
      `Can't find image for trademark ${trademarkId} among these images`,
      images
    )
  }

  return image
}

const FoodserviceTrademarksPage = ({ pageContext, data }) => {
  return (
    <Layout
      pageTitle='Sortiment'
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row row--with-bottom-margin'>
        <section className='section section--with-background'>
          <h1 className='page__heading'>Sortiment</h1>
          <p className='section__text section__text--margin-top'>
            Läs gärna mer om våra produkter genom att klicka på någon av
            kategorierna nedan.
          </p>
          <p>
            Mer information gällande våra produkter hittar du under respektive
            varumärke på{' '}
            <a href='https://www.dabas.com' className='section__link'>
              Dabas artikeldatabas
            </a>
            . Du kan också läsa mer om vårt sortiment i vår{' '}
            <a href='/documents/sortiment.pdf' className='section__link'>
              sortimentsfolder
            </a>
            .
          </p>
        </section>
        <section className='section'>
          <Cards>
            {pageContext.sortiment.map((trademark) => (
              <Trademark
                trademark={trademark}
                image={trademarkImage(data.images.nodes, trademark.id)}
                key={trademark.name}
              />
            ))}
          </Cards>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "cakes_bg2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "foodservice" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 520, quality: 60, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default FoodserviceTrademarksPage
